import * as dateDfn from 'date-fns';

import {EventType} from '../types/eventTypes';
import {sortAlpha} from '../utils/sortAlpha';
import {instance} from './index';

export async function getEventTypesByOrg(idOrganization: string) {
  return instance()
    .get<EventType[]>(`/event-types?idOrganization=${idOrganization}`)
    .then(response => sortAlpha(response.data, 'name'));
}

export async function getEventTypeById(id: string) {
  return instance()
    .get<EventType>(`/event-types/${id}`)
    .then(response => response.data);
}

export function createEventType(data: EventType) {
  return instance().post('/event-types', {
    ...data,
    createdAt: dateDfn.formatISO(new Date()),
  });
}

export function updateEventType(data: EventType) {
  return instance().put(`/event-types/${data.id}`, {
    ...data,
    updatedAt: dateDfn.formatISO(new Date()),
  });
}
