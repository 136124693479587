import * as dateDfn from 'date-fns';

import {LastLocation, Location, UserNearbyToLocation} from '../types/locations';
import {sortAlpha} from '../utils/sortAlpha';
import {instance} from './index';

export async function getLocations() {
  return instance()
    .get<Location[]>('/locations')
    .then(response => sortAlpha(response.data, 'name'));
}

export async function getLastLocation(idUser: string) {
  return instance()
    .get<LastLocation>(`/geolocation/tracking/last-location/${idUser}`)
    .then(response => response.data);
}

export async function getLocationsByIds(ids: string[]) {
  const locationPromises = ids.map(id => {
    return instance()
      .get<Location>(`/locations/${id}`)
      .then(response => response.data);
  });
  const locations = await Promise.all(locationPromises);
  return locations.flat();
}

export async function getUsersNearbyByLocation(idLocation: string) {
  return instance()
    .get<UserNearbyToLocation[]>('/geolocation/tracking/nearby', {
      params: {
        top: 5,
        idLocation: idLocation,
      },
    })
    .then(response => response.data);
}

export async function getLocationsByOrganization(idOrganization: string) {
  return instance()
    .get<Location[]>(`/locations?idOrganization=${idOrganization}`)
    .then(response => sortAlpha(response.data, 'name'));
}

export async function getLocationsByOrganizationEnabled(
  idOrganization: string,
) {
  return instance()
    .get<Location[]>(
      `/locations?idOrganization=${idOrganization}&isEnabled=true`,
    )
    .then(response => sortAlpha(response.data, 'name'));
}

export function createLocation(data) {
  return instance().post('/locations', {
    ...data,
    createdAt: dateDfn.formatISO(new Date()),
  });
}

export function updateLocation(data) {
  return instance().put(`locations/${data.id}`, {
    ...data,
    updatedAt: dateDfn.formatISO(new Date()),
  });
}

export function disableLocation(data) {
  return instance().put(`locations/${data.id}`, {
    ...data,
    updatedAt: dateDfn.formatISO(new Date()),
  });
}
