import React, {useMemo, useState} from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
  FormLabel,
  Select,
  UseDisclosureProps,
} from '@chakra-ui/react';

import {addDays} from 'date-fns';
import {Spanish} from 'flatpickr/dist/l10n/es.js';
import Flatpickr from 'react-flatpickr';
import {useParams} from 'react-router';

import {useProjectsByOrganization} from '../hooks/useProjects';
import {UserList} from '../pages/schedule/UserList';
import {CalendarFilter} from '../slices/calendarFilterSlice';
import {Project} from '../types/projects';
import {TourFilter} from '../types/tours';
import {FiltersUsers, User} from '../types/users';
import {sortAlpha} from '../utils/sortAlpha';
import {getAttribute, hasAccess} from '../utils/user-access';

interface SelectFiltersProps {
  isLoading: boolean;
  users: User[];
  listUsers: FiltersUsers[];
  selectProject: string;
  selectDate?: Date[] | null;
  onChangeProject: (pro: string) => void;
  onChangeDate?: (date: Date[] | null) => void;
  onChangeSelectedUser: (user: FiltersUsers, parent: string[]) => void;
  userSelected: CalendarFilter[] | TourFilter[];
  modal?: UseDisclosureProps;
  wTab?: number;
  typeDate: string;
  type: string;
}

export const SelectFilters = (props: SelectFiltersProps) => {
  const {
    isLoading,
    users,
    listUsers,
    selectProject,
    selectDate,
    onChangeProject,
    onChangeDate,
    onChangeSelectedUser,
    userSelected,
    wTab,
    modal,
    typeDate,
  } = props;
  const {idOrganization} = useParams();
  const rawProjects = useProjectsByOrganization(idOrganization);

  const projects = useMemo(() => {
    const projectsEnabled =
      rawProjects.data.filter((p: Project) => p.enabled) || [];
    if (hasAccess('super-admin') || hasAccess('org-admin')) {
      return projectsEnabled;
    }
    return projectsEnabled.filter((p: Project) => {
      if (!p.idProject) {
        return false;
      }
      return getAttribute<string[]>('projects', []).includes(p.idProject);
    });
  }, [rawProjects.data]);

  const listProject = sortAlpha(projects, 'name');

  const [minDateRange, setMinDateRange] = useState<Date | null>(null);
  const [maxDateRange, setMaxDateRange] = useState<Date | null>(null);

  const onChangeDateRange = (date: Date[]) => {
    if (date.length <= 1) {
      return;
    }
    if (typeDate === 'range') {
      setMinDateRange(date[0]);
      if (date[0] < new Date()) {
        setMaxDateRange(addDays(date[0], 6));
        if (onChangeDate) {
          onChangeDate(date);
        }
      }
    }
  };

  return (
    <Accordion defaultIndex={[0, 1]} allowMultiple w='max-content' maxW='280px'>
      <AccordionItem bg='gray.50' borderTopRadius='18px' borderTopWidth='none'>
        <h2>
          <AccordionButton>
            <Box flex='1' textAlign='left' fontWeight='bold'>
              Filtros
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={0} px={2}>
          <Box bg='gray.50' h='auto' p={4}>
            {selectDate && (
              <FormControl mt={-5} mb={3}>
                <FormLabel fontSize={14}>Fecha:</FormLabel>
                <Flatpickr
                  name='dates'
                  options={{
                    mode: typeDate,
                    locale: Spanish,
                    minDate: minDateRange,
                    maxDate: maxDateRange,
                    disableMobile: 'true',
                  }}
                  placeholder='Seleccione una fecha'
                  value={selectDate}
                  onOpen={() => {
                    setMinDateRange(null);
                    setMaxDateRange(null);
                  }}
                  onChange={onChangeDateRange}
                />
              </FormControl>
            )}
            <FormControl mt={selectDate ? 3 : -5} mb={-2}>
              <FormLabel fontSize={14}>Proyecto</FormLabel>
              <Select
                size='sm'
                value={selectProject}
                placeholder='Seleccione...'
                onChange={e => {
                  onChangeProject(e.target.value);
                }}>
                {listProject?.map((project: Project) => (
                  <option key={project.idProject} value={project.idProject}>
                    {project.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem
        bg='gray.50'
        borderBottomRadius='18px'
        borderTopWidth='none'
        position='relative'
        border='none'>
        <h2>
          <AccordionButton>
            <Box
              flex='1'
              textAlign='left'
              fontWeight='bold'
              w={wTab ? wTab : '240px'}>
              Usuarios
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={1} pt={0} px={2}>
          <UserList
            type={props.type}
            users={users}
            listUsers={listUsers}
            isLoading={isLoading}
            onChangeUser={onChangeSelectedUser}
            modal={modal}
            userSelected={userSelected}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
