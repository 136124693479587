import {endOfMonth, format, formatISO, startOfMonth} from 'date-fns';

import {EventType} from '../types/eventTypes';
import {CopyEvent, Event, EventDrawer, MapEventProps} from '../types/events';
import {Location} from '../types/locations';
import {getEventTypesByOrg} from './eventTypesService';
import {instance} from './index';
import {getLocationsByOrganization} from './locationsService';

export async function getEventsByDate(date: string) {
  return instance()
    .get<Event[]>(`/reports/calendar-events?date=${date}`)
    .then(r => r.data);
}

export async function getEventById(idEvent: string) {
  return instance()
    .get<Event>(`/calendar/${idEvent}`)
    .then(r => r.data);
}

export async function getEventsOfMonthByUserId(idUser: string, date: Date) {
  const start = format(startOfMonth(date), 'yyyy-MM-dd');
  const end = format(endOfMonth(date), 'yyyy-MM-dd');
  return instance()
    .get<Event[]>(
      `/calendar?idUser=${idUser}&dateInterval.start>="${start}"&dateInterval.end<="${end}"`,
    )
    .then(response => response.data);
}

export async function getEventsUserDay(idUser: string, date: string) {
  return instance()
    .get(`/calendar?idUser=${idUser}&dateInterval.start="${date}"`)
    .then(response => response.data);
}

export function createEvent(data: Event) {
  return instance().post('/calendar', {
    ...data,
    createdAt: formatISO(new Date()),
  });
}

export function copyDay(data: CopyEvent) {
  return instance().post('/calendar/copy', data);
}

export function copyWeek(data: CopyEvent) {
  return instance().post('/calendar/copy-week', data);
}

export function moveDay(data: CopyEvent) {
  return instance().put('/calendar/move-date', data);
}

export function updateEvent(data: Event) {
  return instance().put(`/calendar/${data.id}`, {
    ...data,
    updatedAt: formatISO(new Date()),
  });
}

export function deleteEvent(id: string) {
  return instance().delete(`/calendar/${id}`);
}

export async function getEventsUserByDate(
  idOrganization: string,
  idUser: string,
  date: string,
): Promise<EventDrawer[]> {
  const [events, locations, typesEvents] = await Promise.all([
    getEventsUserDay(idUser, date),
    getLocationsByOrganization(idOrganization),
    getEventTypesByOrg(idOrganization),
  ]);

  const data: EventDrawer[] = [];
  for (const event of events) {
    const location = locations.find(
      (location: Location) =>
        location.id?.toUpperCase() === event.idLocation?.toUpperCase(),
    );

    const typeEvent = typesEvents.find(
      (eventType: EventType) => eventType.id === event.idEventType,
    );

    if (!typeEvent) {
      continue;
    }

    data.push(
      fillEventData({
        event,
        location,
        typeEvent,
      }),
    );
  }
  return data;
}

function fillEventData(props: MapEventProps): EventDrawer {
  const {event, location, typeEvent} = props;
  return {
    ...event,
    nameTypeEvent: typeEvent.name,
    color: typeEvent.bgColor || 'red.500',
    nameLocation: location && (location.name || ''),
    external_code: location && (location.external_code || ''),
  };
}
